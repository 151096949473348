import React from 'react';
import PropTypes from 'prop-types';
import { SMainContent, SPageSidebar } from './styles';
import SEO from '../../components/SEO';
import { Container, Row } from 'react-bootstrap';
// import { modifyHTMLContent } from '../../preprocessing/post';
import { getImage } from 'gatsby-plugin-image';
import { SPageH1Title } from '../../containers/Layout/styles';
import { graphql } from 'gatsby';
import Breadcrumbs from '../../components/Breadcrumbs';

// import BlogPageSidebar from 'components/BlogPageSidebar';

const SinglePaslaugosPageTemplate = ({ data, pageContext }) => {
    const content = data.wpPaslauga;
    const resolutions =
        content?.featuredImage?.node?.localFile?.childImageSharp
            ?.gatsbyImageData;

    const image = content.featuredImage
        ? getImage(content.featuredImage.node.localFile)
        : null;
    // const modifiedContent = modifyHTMLContent(content.content, content);

    return (
        <>
            <SEO
                title={content.seo.title}
                description={content.seo.metaDesc}
                imgSrc={image?.images?.fallback?.src}
                schemaMarkup={{
                    '@type': 'NewsArticle',
                    headline: content.seo.title,
                    image: [resolutions?.src],
                    datePublished: content.date,
                    dateModified: content.modified,
                    address: undefined,
                    author: {
                        '@type': 'Person',
                        name: 'FSA',
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: 'Finansinių sprendimų agentūra',
                    },
                }}
            />
            <Container fluid>
            <Breadcrumbs title={content.seo.title} />
                <Row>
                    {/* HERE GOES IMAGE */}
                    <SPageH1Title>{content.seo.title}</SPageH1Title>
                    <SMainContent md={8} xl={9}>
                        <article
                            className="single-blog-post-content"
                            dangerouslySetInnerHTML={{
                                __html: content.content,
                            }}
                        />
                    </SMainContent>
                    <SPageSidebar md={4} xl={3}>
                        {/* <BlogPageSidebar
                                latestBlogposts={latestBlogposts}
                            /> */}
                    </SPageSidebar>
                </Row>
            </Container>
        </>
    );
};

SinglePaslaugosPageTemplate.propTypes = {
    blogPosts: PropTypes.array,
    currentPage: PropTypes.number,
    totalPosts: PropTypes.number,
    paginationBasePath: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    latestBlogposts: PropTypes.array,
};

export default SinglePaslaugosPageTemplate;

export const postQuery = graphql`
    query ($id: String!) {
        wpPaslauga(id: { eq: $id }) {
            content
            date(formatString: "MMM DD, YYYY")
            modified(formatString: "MMM DD, YYYY")
            seo {
                canonical
                metaDesc
                focuskw
                metaKeywords
                title
            }
            title
            date(formatString: "MMM DD, YYYY")
            slug
            featuredImage {
                node {
                    mediaItemUrl
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
            }
        }
    }
`;
